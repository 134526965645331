body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Apply to all scrollable elements */
body::-webkit-scrollbar,
html::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 6px;
}

/* Bar color and rounded ends */
body::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* Darker color on hover */
body::-webkit-scrollbar-thumb:hover,
html::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Hide the scrollbar buttons */
body::-webkit-scrollbar-button,
html::-webkit-scrollbar-button,
*::-webkit-scrollbar-button {
  display: none;
}
